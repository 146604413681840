<template>
	<form enctype="multipart/form-data">
		<input ref="file" type="file" class="file-upload" accept="image/*"
			@change="filesChange"/>
	</form>
</template>

<script>
	import Logo from "@/api/logo"
	
	export default {
		name: "UploadLogo",
		data() {
			return {
				file: null,
			}
		},
		methods: {
			filesChange: function () {
				// handle file changes
				const formData = new FormData();
				formData.append("file", this.$refs.file.files[0])
				
				// save it
				this.uploadLogo(formData);
			},
			uploadLogo: function (formData) {
				formData.append("wallet_addr", this.$store.state.wallet_addr)
				
				Logo.create(formData).then(resp => {
					console.log("logo uploaded: ", resp.data)
					this.$emit("uploaded", resp.data)
				})
			}
		}
	}
</script>

<style scoped>
	.file-upload::-webkit-file-upload-button {
		visibility: hidden;
	}
	
	.file-upload::before {
		color: white;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		content: "Drag & Drop to Upload";
		white-space: break-spaces;
		outline: none;
		-webkit-user-select: none;
		cursor: pointer;
	}
	
	.file-upload {
		content: '';
		border: 1px solid #ccc;
		display: inline-block;
		cursor: pointer;
		height: 100px;
		padding: 5px;
		font-size: 0.7rem;
		text-align: center;
	}
</style>