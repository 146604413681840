import {api, apiHelper} from "./api";

export class Logo {
	prefix() {
		return "auth/users/logos"
	}

	index(wallet_addr, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}?user=${wallet_addr}&${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	show(uid) {
		return api.get(`${this.prefix()}/${uid}`)
	}

	showID(id) {
		return api.get(`/uploads/logos_id/${id}`)
	}

	create(formData) {
		return api.post(`${this.prefix()}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		})
	}

	delete(uid) {
		return api.delete(`${this.prefix()}/${uid}`)
	}
}

export default new Logo()