<template>
	<div class="logo-container row">
		<div v-on:click="setActive(logo)" :class="`logo col-sm-2 ${logo.public_uri === active ? 'active' : ''}`" v-for="logo in logos" :key="logo.uuid">
			<img class="img-fluid" :alt="logo.name" :src="logo.public_uri">
		</div>
		<UploadLogo class="col-sm-2" @uploaded="indexLogos"></UploadLogo>
	</div>
</template>

<script>
	import Logo from "@/api/logo"
	import UploadLogo from "./UploadLogo";
	
	export default {
		name: "IndexLogoUploads",
		components: {UploadLogo},
		props: {
			active: {
				type: String
			}
		},
		data() {
			return {
				logos: [],
			}
		},
		methods: {
			indexLogos: function () {
				Logo.index(this.$store.state.wallet_addr).then(resp => this.logos = resp.data)
			},
			setActive: function(logo) {
				this.$emit("set-active", logo)
			}
		},
		mounted() {
			this.indexLogos()
		}
	}
</script>

<style scoped>
	.logo img {
		max-height: 100px;
		border: 1px solid #000;
		transition: all 0.3s ease-in-out;
	}
	
	.logo img:hover, .logo.active img {
		transform: scale(1.1);
		border: 1px solid #0a9fb9;
	}
</style>