<template>
	<div class="col-12">
		<table class="table">
			<tbody>
			<tr>
				<td>Portfolio Total:</td>
				<td><code>{{ fmt.format(portfolio.overview.balance) }}</code> {{ portfolio.overview.currency }}</td>
			</tr>
			<tr>
				<td>NFT's Owned:</td>
				<td><code>{{ portfolio.overview.nft_owned }}</code></td>
			</tr>
			<tr>
				<td>Land Owned:</td>
				<td><code>{{ portfolio.overview.land_owned }}</code></td>
			</tr>
			<tr>
				<td>Wearables Owned:</td>
				<td><code>{{ portfolio.overview.wearable_owned }}</code></td>
			</tr>
			<tr>
				<td>Staking:</td>
				<td><code>{{ fmt.format(portfolio.overview.staked) }}</code> {{ portfolio.overview.currency }} - <code>{{ fmt.format(portfolio.overview.pending) }}</code> {{ portfolio.overview.currency }} <span class="stake-pending">Pending</span></td>
			</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	import moment from "moment"
	
	export default {
		name: "TokenCard",
		props: {
			profile: {
				type: Object,
				required: true
			},
		},
		data() {
			return {
				fmt: new Intl.NumberFormat(),
				portfolio: {
					overview: {
						currency: "USDT",
						balance: 200000, //ABMT
						nft_owned: 1,
						land_owned: 2,
						wearable_owned: 2,
						staked: 20000,
						pending: 200,
						unique_tokens: 5,
					},
					tokens: [
						{name: "USDT", symbol: "USDT", value: 20000},
						{name: "AlphaBatem Token", symbol: "ABMT", value: 200000},
						{name: "ChainLink", symbol: "LINK", value: 222},
						{name: "Solana", symbol: "SOL", value: 100},
						{name: "Internet Computer Machine", symbol: "ICP", value: 44000},
					]
				}
			}
		},
		computed: {
			humanDate: function () {
				return moment.duration(-moment(this.profile.last_login_at).unix()).humanize(true)
			}
		}
	}
</script>

<style scoped>
	td {
		color: white;
		font-size: 1rem;
	}
	
	code {
		font-size: 0.9rem;
	}
	
	.stake-pending {
		font-style: italic;
		font-size: 0.8rem;
	}
</style>