<template>
	<div class="container-fluid mt-5">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<PlayerCard :profile="profile"></PlayerCard>
					</div>
				</div>
			</div>
			
			<div class="row mt-3">
				<div class="col-12">
					<div class="card">
						<h1 class="mb-3">Update Profile</h1>
						<div class="row my-5">
							<div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12">
								<form class="form">
									<div class="form-group mb-5">
										<label>Username</label>
										<input type="text" class="form-control" v-model="form.name">
									</div>
									
									<div class="form-group mb-5">
										<label class="mb-2">Profile Image</label>
										
										<IndexLogoUploads @set-active="onLogoUpdate" :active="profile.avatar_url"></IndexLogoUploads>
									</div>
									
									
									<button class="btn btn-block btn-primary" v-on:click="updateProfile">Update</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="row mt-3">
				<div class="col-12">
					<div class="card">
						<h1 class="mb-3">Guild</h1>
						<div class="row my-5">
							<div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12 text-center">
								<h2 class="my-5">No Current Guild</h2>
								<button class="btn btn-primary" v-on:click="leaveGuild" disabled>Leave Guild</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		
		</div>
	</div>
</template>

<script>
	import Auth from "@/api/auth"
	import User from "@/api/user"
	
	import PlayerCard from "../../components/profile/PlayerCard";
	import IndexLogoUploads from "../../components/upload/IndexLogoUploads";
	
	export default {
		name: "ProfileUpdate",
		components: {IndexLogoUploads, PlayerCard},
		data() {
			return {
				user: "",
				profile: {},
				form: {
					name: "",
					avatar_url: "",
				}
			}
		},
		methods: {
			getProfile: function () {
				User.show(this.user).then(resp => {
					this.profile = resp.data;
					this.form = resp.data;
				})
			},
			updateProfile: function (e) {
				e.preventDefault()
				
				//Web3ProfileUpdate
				const payload = {
					public_address: this.$store.state.wallet_addr,
					name: this.form.name,
					logo_id: this.form.logo_id,
					// sprite_url: "",
				}
				
				const encodedMessage = new TextEncoder().encode("Profile Update");
				window.solana.signMessage(encodedMessage, "utf8").then(sig => {
					console.log("Profile update approved", sig);
					
					//TODO Reenable this
					// payload.signature = sig;
					
					Auth.update(this.$store.state.wallet_addr, payload).then(resp => {
						console.log("Profile updated", resp.data)
						this.$router.push("/profile")
					})
				})
				
			},
			
			onLogoUpdate: function (logo) {
				console.log("setting active logo: ", logo);
				this.profile.avatar_url = logo.public_uri
				this.form.logo_id = logo.uuid
			},
			leaveGuild: function (e) {
				e.preventDefault()
				Auth.leaveGuild(this.$store.state.wallet_addr).then(resp => {
					console.log("Profile updated", resp.data)
					this.$router.push("/profile")
				})
			}
		},
		beforeMount() {
			this.user = this.$store.state.wallet_addr
		},
		mounted() {
			this.getProfile();
		}
	}
</script>

<style scoped>
	form {
		color: white;
	}
	
	.btn, h2 {
		color: white;
	}
</style>